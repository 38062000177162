// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-all-document-tsx": () => import("./../src/templates/allDocument.tsx" /* webpackChunkName: "component---src-templates-all-document-tsx" */),
  "component---src-templates-all-employee-tsx": () => import("./../src/templates/allEmployee.tsx" /* webpackChunkName: "component---src-templates-all-employee-tsx" */),
  "component---src-templates-all-gallery-tsx": () => import("./../src/templates/allGallery.tsx" /* webpackChunkName: "component---src-templates-all-gallery-tsx" */),
  "component---src-templates-all-gutak-tsx": () => import("./../src/templates/allGutak.tsx" /* webpackChunkName: "component---src-templates-all-gutak-tsx" */),
  "component---src-templates-category-tsx": () => import("./../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */),
  "component---src-templates-home-tsx": () => import("./../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

